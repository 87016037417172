import * as React from "react"
import {Link, HeadFC, graphql} from "gatsby"
import NotFound from "../components/NotFound/not-found";
import {useTranslation} from "react-i18next";
import {SEO} from "../components/Seo/seo";

const NotFoundPage = () => {
  const {t} = useTranslation();
  return (
    <NotFound/>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <SEO title={"👀 Not found"}/>

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
import * as React from "react";
import "./not-found.scss";
import LogoAsset from "../../../assets/markana-logo.svg";
import {Link} from "gatsby";
const NotFound = () => (
    <main className="not-found">
        <LogoAsset className="not-found_logo"/>
        <h1>404 - Not found</h1>
        <p>Page that you looking for does not exist.</p>
        <Link to={"/"}>Go back to home page</Link>
    </main>
);

export default NotFound;